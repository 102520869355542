/* .panel-title{
    color : #035fa4 
} */
#auditreport {
    display: none;
}

#export_button {
    display: none;
    margin-top: 2%;

}

#errorMsg {
    display: none;
    margin-top: 2%;
}