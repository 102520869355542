.error{
    color:red;
    font-size: 17;
    margin-bottom:5;
    
}

.success{
    color:green;
    font-size: 17;
    margin-bottom:5;
   
}

.divElement{
  position: relative;
  z-index: 1000;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}​


.tableFixHead    { overflow-y: auto; height: 100px; }
.tableFixHead th { position: sticky; top: -1px;background:#eee; }
.tableFixHead tbody {height:300%}

/* style={{ alignContent: "center", width: 1300, height: 5, marginLeft: -100, "tableLayout": "fixed", "overflow": "hidden", "overflowY": "scroll" }} */

.tabScrl {
align-content: center;
height: 5;
table-layout: fixed;
overflow-y: scroll;
overflow: hidden;
}

@keyframes fadeIn {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
      color: transparent;
    }
  }
  