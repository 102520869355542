body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.load-spinner{
	display: flex;
	flex-direction: column;
  align-items: center;
}
