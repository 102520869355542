html {
  font-size: 16px !important;
}

/* table {
    font-family: "FS Elliot Web Regular","FS Elliot Web Regular","Arial",arial,sans-serif !important;
} */
.MuiTable-root {
  font-family: "FS Elliot Web Regular", "FS Elliot Web Regular", "Arial", arial,
    sans-serif !important;
  font-size: 16px !important;
}
.MuiContainer-root {
  font-family: "FS Elliot Web Regular", "FS Elliot Web Regular", "Arial", arial,
    sans-serif !important;
  font-size: 16px !important;
}
.MuiTableCell-root {
  font-family: "FS Elliot Web Regular", "FS Elliot Web Regular", "Arial", arial,
    sans-serif !important;
  font-size: 16px !important;
}
.MuiTableHead-root {
  font-family: "FS Elliot Web Bold", "FS Elliot Web Bold", "Arial", arial,
    sans-serif !important;
  font-size: 16px !important;
}

.MTableToolbar-spacer-7 {
  flex: 0 0 0% !important;
}
.MTableToolbar-title-9 {
  display: none !important;
}
.MTableToolbar-searchField-10 {
  padding-left: 0px !important;
}

#parent {
  display: flex;
}

.add-model-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  padding-left: 20px;
}

.model-table-margin {
  margin-top: 15px;
}

.load-spinner{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tableFixHead    { overflow-y: auto; height: 100px; }
.tableFixHead th { position: sticky; top: -1px;background:#eee; }
.tableFixHead tbody {height:300%}

.hidden { display:none; }
